import React, { Component } from "react"
import SignatureCanvas from 'react-signature-canvas'
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import Slider from 'rc-slider';
import '../assets/css/slider.css';

import Layout from "../components/layout"
import Steps from "../components/steps"
import bottomLine from "../images/line.png"
import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import MyInput from "../components/MyInput"
import MyCheckbox from "../components/MyCheckbox";
import MyInputMask from "../components/MyInputMask";
import ButtonLoader from "../components/buttonLoader";
import infosvg from "../images/info.svg"


class Agre5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      newsub:true,
      infoError:null,
      agre5Data:{name:'',title:'',phone:'',email:'',w9form:false,dealStructure:'0',products:[],fiDepart:true,servDepart:true},
      prolist:["VSC","Lease Care","Tire & Wheel"],
      selectedFile:null,
      marketVal:3,
      marketDet:{
        1:{title:"Very Soft Approach", desc:"Lowest results, email only."},
        2:{title:"Soft Approach", desc:"Email and light phone activity."},
        3:{title:"Moderate Approach", desc:"Email, phone, and texting activity."},
        4:{title:"Active Approach", desc:"Increased email, phone, and texting activity."},
        5:{title:"Gung-Ho!", desc:"Persistent email, phone, and texting activity; best results!"}
      }
    };
   
  }
  sigPad = {}
  
  componentDidMount = async () => {
    if(!sessionStorage.agre4Data){
      navigate("/signup"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
    }
    if(!sessionStorage.version || sessionStorage.version!==process.env.signup_lsid){
      sessionStorage.clear();
      sessionStorage.version=process.env.signup_lsid;
    }
    if(!sessionStorage.agre5Data){
        sessionStorage.agre5Data = window.btoa(JSON.stringify(this.state.agre5Data))
    } 
    this.state.agre5Data = JSON.parse(window.atob(sessionStorage.agre5Data))
    this.setState({agre5Data:JSON.parse(window.atob(sessionStorage.agre5Data))});
    console.log(process.env.BEDFORD_API_URL)
  }

  sugChange = async(value,type) =>{
    let agre5Data = this.state.agre5Data;
    agre5Data.title = value;
    this.setState({agre5Data:agre5Data});
   
  }

  featureChanges = (ele, mythis, value) => {
    let agre5Data = this.state.agre5Data;
    
    agre5Data.w9form = value;
    
    if(!value) this.setState({ selectedFile: null })

    this.setState({ agre5Data: agre5Data })
    this.checkMealso();
    
  }
  dealStructure = (ele, mythis, value) => {
    let agre5Data = this.state.agre5Data;
    
    if(value)
      agre5Data.dealStructure = ele;
    else{
      if(ele==='0')
        agre5Data.dealStructure = '50';
      else
        agre5Data.dealStructure = '0';
    }
    
    
    this.setState({ agre5Data: agre5Data });
    this.state.agre5Data = agre5Data;
    
  }
  clear = () => {
    this.sigPad.clear();
    this.checkMealso();
  }
  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    this.setState({ canSubmit: true });
  }

  slideChange = (val) => {
    this.setState({ marketVal: val });
  }

  checkMealso= () => {
    if(this.state.agre5Data.w9form){
      if(this.state.selectedFile){
        this.setState({ newsub: true,infoError:"" });
        this.state.newsub = true;
      } else if(this.state.agre5Data.dealStructure) {
        this.setState({ newsub: false,infoError:"Please upload W9 form" });
        this.state.newsub = false;
      } else {
        this.setState({ newsub: false,infoError:"Please upload W9 form" });
        this.state.newsub = false;
      }
    } else if(this.state.agre5Data.dealStructure) {
      this.setState({ newsub: true,infoError:"" });
      this.state.newsub = true;
    } else {
      this.setState({ newsub: false,infoError:"" });
      this.state.newsub = false;
    }

    /* if(this.state.newsub){
      if(this.sigPad.isEmpty()){
        this.setState({ newsub: false,infoError:"Please draw your signature" });
      }
    } */

  }
  cbchange = async(name,type,bool,value) =>{
    let agre5Data = this.state.agre5Data;
    if(type == "radio"){
        if(bool){
            agre5Data[name] = value;
        } else {
            agre5Data[name] = "";
        }
    } else {
      let indx = agre5Data[name].indexOf(value);
      if(indx>=0){
        if(!bool){
          agre5Data[name].splice(indx, 1);
        }
      } else {
        if(bool){
          agre5Data[name].push(value);
        }
      }
    }
    this.state.agre5Data = agre5Data;
    this.setState({agre5Data:agre5Data});
    
  }
  submit = async(model) => {
    const { showLoader, hideLoader} = this.props;
    
     showLoader();
     model.dealStructure = this.state.agre5Data.dealStructure;
     model.mfTitle = this.state.marketDet[ this.state.marketVal]['title'];
     model.mfDesc = this.state.marketDet[ this.state.marketVal]['desc'];
     model.products = this.state.agre5Data.products;
     model.fiDepart = this.state.agre5Data.fiDepart;
     model.servDepart = this.state.agre5Data.servDepart;

      sessionStorage.agre5Data = window.btoa(JSON.stringify(model));
      //console.log(sessionStorage)
      const data = new FormData() 
      data.append('file', this.state.selectedFile);
      //console.log(sessionStorage.agre1Data)
      let info = {
        'dealerInfo': JSON.parse(window.atob(sessionStorage.agre1Data)),
        'fiInfo': JSON.parse(window.atob(sessionStorage.agre2Data)),
        'sysInfo': JSON.parse(window.atob(sessionStorage.agre3Data)),
        'dmsType': JSON.parse(window.atob(sessionStorage.agre4Data)),
        'payee': model
      }
      data.append('sign',this.sigPad.getTrimmedCanvas().toDataURL('image/png'))
      data.append('agrinfo',JSON.stringify(info))
     
      await fetch(process.env.BEDFORD_API_URL,{method: "POST", body: data})
      .then(res => res.json())
      .then((res) => {
        sessionStorage.clear();
        hideLoader();
        navigate("/thanks"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
      });
  }

  onChangeHandler=event=>{
    this.setState({ selectedFile: event.target.files[0]})
    this.state.selectedFile = event.target.files[0];
    this.checkMealso();
  }
   
  render() {
    const { agre5Data, canSubmit, newsub, infoError,marketVal,marketDet,prolist } = this.state;
    const { buttonLoader} = this.props;

    
   
    return (
      <Layout layoutType="wizard" >
        <SEO title="Dealer Agreement" keywords={[`dealer`, `agreement`, `contacts`]} />
        <section>
        <Steps></Steps>
          <div className="custom-container vehinfo">
            <div className="title-container">
              {/* <div className="icon-image">
                <img src={vehicalIcon} alt="icon" />
              </div> */}
              <div className="title-text">
                <h1>{'COMMISSION PAYEE'}</h1>
                <p></p>
              </div>
            </div>

            <div className="body-content">
              <Formsy ref="agre2Form" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>

              <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                            <MyInput name="name" id="name" placeholder="Name"  value={agre5Data.name} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} required />
                        </div>

                    </div>
                    
                    <div className="form-right">
                        <div className="form-label-group error">
                        {/* <label>Title</label>
                        <MyAuto value={agre5Data.title} options={['Mr.','Ms.','Mrs.']}  sugChange={(e) => this.sugChange(e, 'title')} name="title" /> */}
                        <MyInput name="title" id="title" placeholder="Title"  value={agre5Data.title} validationError="Please enter a valid title."
                                isLabelRequired={true} className="form-control" validations={{minLength: 1,maxLength:25}} required />
                        </div>
                    </div>
                    
                </div>
                

                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                        <MyInputMask name="phone" id="phone" placeholder="Phone" mask="999-999-9999" value={agre5Data.phone} isLabelRequired={true}
                            className="form-control" validations={{minLength: 12,maxLength:15}} validationError="Please enter a valid Phone." required />
                        
                        </div>

                    </div>
                    <div className="form-right">
                        <div className="form-label-group error">
                        <MyInput name="email" id="email" placeholder="Email"  value={agre5Data.email} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email" required />
                        </div>
                    </div>
                   
                </div>

                <div className="form-container-inline">
                    <div className="form-left">
                      <div className="check-box-container">
                        <div className="form-check">
                          <MyCheckbox name="w9form" id="w9form" labelClass="check-container big" value={agre5Data.w9form}
                                label="W9" checkBoxChange={(e) => this.featureChanges('ADP', this, e)} />
                        </div>  
                          
                        {agre5Data.w9form ?
                        <div className="form-check">
                          <input className="form-control"  id="contained-button-file" type="file" onChange={this.onChangeHandler} />
                        </div>
                        :''}
                        <div className="form-check"></div>
                        
                      </div>

                      <div className="form-container-inline">
                      <div className="myform">
                        <div className="h4my">Products</div>
                        <div className="check-box-container">
                        {
                          prolist.map((val,ky) => {
                            let tmp = agre5Data.products.indexOf(val);
                            return (
                              <div className="form-check" key={"pa"+ky}>
                                <MyCheckbox
                                    name={"pa"+ky} id={"pa"+ky} labelClass="check-container big" label={val} 
                                    value={tmp>=0?true:false}
                                    checkBoxChange={(e) => this.cbchange('products', 'cb', e,val)}
                                />
                              </div>
                            );
                          })
                        }
                        </div>
                      </div>
                    </div>

                      <h3 className="h4my">DEAL STRUCTURE * <a href="https://dowc-asserts.s3.amazonaws.com/Sale_kit-1.pdf" target="_blank"><img src={infosvg} width="25" /></a></h3>
                  <div className="check-box-container">
                    <div className="form-check">
                        <MyCheckbox
                            name="ds0" id="opt1" labelClass="check-container big round" value={agre5Data.dealStructure==='0'?true:false}
                            label="Zero Upfront Costs and Zero Monthly Costs" checkBoxChange={(e) => this.dealStructure('0', this, e)}
                        />
                    </div>
                    <div className="form-check">
                        <MyCheckbox
                            name="ds50" id="opt2" labelClass="check-container big round" value={agre5Data.dealStructure==='50'?true:false}
                            label="50/50 Profit Share" checkBoxChange={(e) => this.dealStructure('50', this, e)}
                        />
                    </div>
                    </div>
                    <br />
                    <h3 className="h4my">Marketing Frequency</h3> 
                    <div className="check-box-container">
                    <Slider min={1} max={5} defaultValue={marketVal} marks={{ 1: 1, 2: 2, 3: 3,4:4, 5:5 }} step={null} onChange={this.slideChange} />
                    </div>
                    <br />
                    <label><b>{marketDet[marketVal]['title']}</b><br />{marketDet[marketVal]['desc']}</label>
                    <br />
                    <br />

                <div className="form-container-inline">
                <div className="myform">
                  <div className="h4my">Would you like to offer this in your F&I Department?</div>
                  <div className="check-box-container nln">
                  
                        <div className="form-check">
                          <MyCheckbox
                              name="fiDepart" labelClass="check-container big round" 
                              label="Yes" 
                              value={agre5Data.fiDepart?true:false}
                              checkBoxChange={(e) => this.cbchange('fiDepart', 'radio', e,true)}
                          />

                          <MyCheckbox
                              name="fiDepart" labelClass="check-container big round" 
                              label="No" 
                              value={agre5Data.fiDepart?false:true}
                              checkBoxChange={(e) => this.cbchange('fiDepart', 'radio', e,false)}
                          />
                        </div>
                  
                  </div>
                </div>
              </div>

              <div className="form-container-inline">
                <div className="myform">
                  <div className="h4my">Would you like to offer this in your Service Department?</div>
                  <div className="check-box-container nln">
                  
                        <div className="form-check">
                          <MyCheckbox
                              name="servDepart" labelClass="check-container big round" 
                              label="Yes" 
                              value={agre5Data.servDepart?true:false}
                              checkBoxChange={(e) => this.cbchange('servDepart', 'radio', e,true)}
                          />

                          <MyCheckbox
                              name="servDepart" labelClass="check-container big round" 
                              label="No" 
                              value={agre5Data.servDepart?false:true}
                              checkBoxChange={(e) => this.cbchange('servDepart', 'radio', e,false)}
                          />
                        </div>
                  
                  </div>
                </div>
              </div>
                    
                    </div>
                    <div className="form-right signat">
                    <div className="form-label-group error">
                        <label>Signature</label>
                        {/* <SignatureCanvas penColor='green' canvasProps={{height: 150, className: 'sigCanvas'}} /> */}
                        <SignatureCanvas canvasProps={{className: 'sigCanvas'}} 
                        throttle={0} dotSize={2} minWidth={0.1} maxWidth={2} minDistance={1}
                        ref={(ref) => { this.sigPad = ref }}
                        onEnd={(e) => this.checkMealso()}
                         />
                        <a className="clearbtn" onClick={this.clear}>Clear</a>
                        </div>
                        
                        
                    </div>
                </div>

                
                <div className="form-container-inline">
                  <div className="form-left">
                  
                    </div>

                    


                </div>
                
                


                <h3 className="sub-text mb-12"></h3>
                <div className="error-txt">{infoError}</div>
                <div className="btn-container">
                  
                    <button type="submit" disabled={!canSubmit || !newsub || buttonLoader} className={!canSubmit || !newsub || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                        <ButtonLoader isLoading={buttonLoader} label={'Submit'} />
                    </button>
                </div>

              </Formsy>
              <div className="txtright">* Field is required</div>
            </div>
          </div>
          <div className="vehicle-bottom-line bottom-line">
            <img src={bottomLine} alt="line" />
          </div>
        </section>


      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Agre5);